import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import dynamic from 'next/dynamic';

const NavigationHeaderAlgolia = dynamic(() => import('../../tissues/navigation-header'));
const CheckoutHeader = dynamic(() => import('../../molecules/checkout-header'));
const NavigationMenu = dynamic(() => import('../../tissues/navigation'));
// TODO: [TEC-8711] uncomment the code below to add quicklinks bar back
// @see https://maisonette.atlassian.net/browse/TEC-8711
// const MobileNavigationMenu = dynamic(() => import('../../tissues/mobile-navigation'));
const PetiteDropDown = dynamic(() => import('../../tissues/petite-dropdown'));
const PromotionBanner = dynamic(() => import('../../tissues/promotion-copy'));

const StickyContainer = styled.div`
  position: sticky;
  z-index: ${(props) => props.theme.layers.downstage - 1};
  top: 0;
`;

const SlidingContainer = styled.div`
  z-index: ${(props) => props.theme.layers.downstage - 4};
  top: ${(props) => props.isPetiteDropDown ? '146px' : '118px'};
  transition: transform ${(props) => props.theme.animation.slow} ease;

  @media screen and (min-width: ${(props) => props.theme.breakpoint.medium}) {
    top: 124px;
  }
`;

const Vestibule = (props) => {
  const sliderRef = useRef();
  let ticking = false;
  let previousY = 0;

  const renderPromotionBanner = props.showPromotion ? !(props.isPdp && props.isMobilePaidPDP && props.isPdpPromoBannerVisible) : false;
  

  return (
    <>
      <StickyContainer>
        {
          props.isCheckout
            ? (
              <>
                <CheckoutHeader />
                <SlidingContainer ref={sliderRef}>
                  {props.showPromotion && (
                    <PromotionBanner content={props.promotionContent} showPromotionsLoader={props.showPromotionsLoader} />
                  )}
                </SlidingContainer>
              </>
            )
            : (
              <>
                <NavigationHeaderAlgolia />
              </>
            )
        }
      </StickyContainer>

      {props.user?.spree_api_key && <PetiteDropDown />}

      {
        !props.noNav && (
          // TODO: props.noNav and props.isCheckout are only passed into the checkout layout
          // this is an artifact from checkout revamp and can be refactored
          <>
            <NavigationMenu
              navigation={props.navigation}
            />

            <SlidingContainer ref={sliderRef} isPetiteDropDown={props.user?.spree_api_key && props.minis?.length}>
              {/* {
                TODO: [TEC-8711] uncomment the code below to add quicklinks bar back
                @see https://maisonette.atlassian.net/browse/TEC-8711

                !props.isMobilePaidPDP && props.mobileNavigation !== null && (
                  <MobileNavigationMenu
                    navigation={props.mobileNavigation}
                    profile={props.profile}
                    isCheckout={props.isCheckout}
                  />
                )
              } */}
              {renderPromotionBanner && (
                <PromotionBanner
                  content={props.promotionContent}
                  isNavigationActive={props.isNavigationActive}
                  showPromotionsLoader={props.showPromotionsLoader}
                />
              )}
            </SlidingContainer>
          </>
        )
      }
    </>
  )
};

Vestibule.defaultProps = {
  promotionContent: [],
  user: {},
  profile: {},
  isCheckout: false,
  noNav: false,
  isPetiteDropdownActive: false,
  isNavigationActive: false,
  isMobilePaidPDP: false,
  showPromotion: true,
  showPromotionsLoader: true,
  isPdpPromoBannerVisible: false,
  isPdp: false,
};

Vestibule.propTypes = {
  navigation: PropTypes.object.isRequired,
  mobileNavigation: PropTypes.array.isRequired,
  promotionContent: PropTypes.array,
  user: PropTypes.object,
  profile: PropTypes.object,
  isCheckout: PropTypes.bool,
  noNav: PropTypes.bool,
  isPetiteDropdownActive: PropTypes.bool,
  isNavigationActive: PropTypes.bool,
  isMobilePaidPDP: PropTypes.bool,
  showPromotion: PropTypes.bool,
  showPromotionsLoader: PropTypes.bool,
  minis: PropTypes.array,
  isPdpPromoBannerVisible: PropTypes.bool,
  isPdp: PropTypes.bool
};

const mapStateToProps = (state) => ({
  user: state.user,
  profile: state.profile,
  isPetiteDropdownActive: state.interfaces.isPetiteDropdownActive,
  isNavigationActive: state.interfaces.isNavigationActive,
  showPromotion: state.promotions.showPromotion,
  showPromotionsLoader: state.promotions.showPromotionsLoader,
  minis: state.petites.minis,
});

const ConnectedVestibule = connect(mapStateToProps, null)(Vestibule);

Vestibule.whyDidYouRender = true;

export default ConnectedVestibule;
